import React from "react";
import { graphql } from "gatsby";
import Layout from "@/components/layout";
import MenuContextProvider from "@/context/menu-context";

import Img from "@/components/img";
import Link from "@/components/link";
import HeaderOne from "@/components/header-one";
import { Container, Row, Col } from "react-bootstrap";
import PageHeader from "@/components/page-header";
import Footer from "@/components/footer";
import Ferrara04 from "@/images/ferrara/ferrara_04.jpg";

export default class BlogList extends React.Component {
  render() {
    const { currentPage, numPages } = this.props.pageContext;
    const isFirst = currentPage === 1;
    const isLast = currentPage === numPages;
    const prevPage =
      currentPage === 2 ? "/articles" : `/articles/${currentPage - 1}`;
    const nextPage = (currentPage + 1).toString();
    const posts = this.props.data.allSanityPost.edges;
    return (
      <MenuContextProvider>
        <Layout PageTitle="Posts">
          <HeaderOne />
          <PageHeader title="Articles" image={Ferrara04} name="Articles" />
          <section className="blog_share_area section_padding blog-page">
            <Container>
              <Row className="flex">
                {posts.map(({ node }) => (
                  <Col
                    className="flex-1 flex flex-col"
                    lg={4}
                    md={6}
                    sm={12}
                    key={node._id}
                  >
                    <div className="blog_share_box flex-1">
                      {node.mainImage != null ? (
                        <div className="bl_share_img">
                          <Img
                            src={node.mainImage.asset.url}
                            alt={node.title}
                            className="img-fluid"
                          />
                          {node.publishedAt != null && (
                            <span className="blog_date">
                              {node.publishedAt}
                            </span>
                          )}
                        </div>
                      ) : (
                        <div className="bl_share_img">
                          <Img
                            src="../../breadcrumb.jpg"
                            alt={node.title}
                            className="img-fluid"
                          />
                          {node.publishedAt != null && (
                            <span className="blog_date">
                              {node.publishedAt}
                            </span>
                          )}
                        </div>
                      )}
                      <div className="blog_share_details">
                        {node.author != null && (
                          <span className="comment_author">
                            by{" "}
                            <Link href={"/articles/" + node.slug.current}>
                              {node.author.name}
                            </Link>
                          </span>
                        )}
                        <h1>
                          <Link href={"/articles/" + node.slug.current}>
                            {node.title}
                          </Link>
                        </h1>
                        {node.author != null && (
                          <p
                            dangerouslySetInnerHTML={{ __html: node.excerpt }}
                          ></p>
                        )}
                      </div>
                    </div>
                  </Col>
                ))}
              </Row>
            </Container>
            <div className="mt-8 flex w-full justify-items-center justify-center">
              <p className="text-gray-500">
                Showing <span className="font-medium"> {currentPage} </span> of{" "}
                <span className="font-medium"> {numPages} </span> pages
              </p>
            </div>
            <div className="gap-2 flex w-full justify-items-center justify-center">
              {!isFirst && (
                <Link href={prevPage} className="mr-6" rel="prev">
                  ← Previous Page
                </Link>
              )}
              {/* {Array.from({ length: numPages }, (_, i) => (
                  <Link key={`pagination-number${i + 1}`} href={`/articles/${i === 0 ? "" : i + 1}`}>
                    {i + 1}
                  </Link>
                ))} */}
              {!isLast && (
                <Link
                  href={"/articles/" + nextPage}
                  className="ml-6"
                  rel="next"
                >
                  Next Page →
                </Link>
              )}
            </div>
          </section>
          <Footer />
        </Layout>
      </MenuContextProvider>
    );
  }
}

export const query = graphql`
  query blogIndexPosts($skip: Int!, $limit: Int!) {
    allSanityPost(
      sort: { fields: [publishedAt], order: DESC }
      filter: {
        publishedAt: {
          gte: "2020-01-01T00:00:00Z" # This line filters posts published after January 1, 2020
          ne: null
        }
      }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          publishedAt(formatString: "MMM Do, YYYY")
          publishedDate: publishedAt(formatString: "MM/DD/YYYY")
          slug {
            current
          }
          title
          mainImage {
            asset {
              url
            }
          }
          author {
            name
          }
          _id
          excerpt
        }
      }
    }
  }
`;
